<template>
  <div class="confirm">
    <h2 class="confirm__title">Confirm Modal</h2>
    <div class="confirm__wrap">
      <h3 class="confirm__subtitle">Confirm Modal Default</h3>
      <Button :title="'Показать модальное окно'" @handleButton="openModal" />
      <ConfirmModal v-if="showModal" @cancel="closeModal" :title="'Удалить команду?'" :description="'Восстановить данные не получится. Вам придется создавать команду заново. Вы уверены, что хотите удалить команду?'" />
    </div>
    <div class="confirm__wrap">
      <h3 class="confirm__subtitle">Confirm Modal Props</h3>
      <ul class="confirm__list">
        <li class="confirm__item"><span>title</span> - заголовок подтверждающего действия</li>
        <li class="confirm__item"><span>description</span> - уточнение подтверждающего действия</li>
        <li class="confirm__item"><span>cancelTitle</span> - текст кнопки Отмена</li>
        <li class="confirm__item"><span>acceptTitle</span> - тест кнопки Подтверждения</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/modules/UiKit/components/Confirm/ConfirmModal";
import Button from "@/modules/UiKit/components/buttons/Button";

export default {
  name: "ConfirmModalPage",
  mixins: [],
  props: [],
  components: { Button, ConfirmModal},
  data() {
    return {
      showModal: false
    }
  },
  mounted() {
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  },
  computed: {},
  watch: {},
  validations: {}
}
</script>


<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.confirm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>

